import React from 'react'
import { createRoot } from 'react-dom/client'

import { GoogleAnalyticsService } from '@utils/GoogleAnalyticsService'
import { HotjarService } from '@utils/HotjarService'

import { RootContainer } from '@containers/root.container'

GoogleAnalyticsService.init()
HotjarService.init()

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RootContainer />
  </React.StrictMode>,
)
