import MuiThemeProvider from '@mui/material/styles/ThemeProvider'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'

import { RootLayout } from '@layouts/root'
import { MuiTheme } from '@styles/theme.mui'
import { StyledTheme, GlobalStyle } from '@styles/theme.styled'
import { RouterContainer } from '@containers/router.container'

import { store } from '@libs/redux/store'
import { apolloClient } from '@libs/apollo/client'
import { useJimo } from '@libs/jimo/useJimo'
import { useClarity } from '@libs/clarity/useClarity'
import { useCrisp } from '@libs/crisp/useCrisp'

export const RootContainer = (): JSX.Element => {
  useJimo()
  useClarity()
  useCrisp()

  return (
    <RootLayout>
      <MuiThemeProvider theme={MuiTheme}>
        <StyledThemeProvider theme={StyledTheme}>
          <GlobalStyle />
          <Provider store={store}>
            <ApolloProvider client={apolloClient}>
              <RouterContainer />
            </ApolloProvider>
          </Provider>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </RootLayout>
  )
}
