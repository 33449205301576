import { useEffect } from 'react'

const CRISP_WEBSITE_ID: string | undefined = import.meta.env.VITE_CRISP_WEBSITE_ID

export const useCrisp = () => {
  useEffect(() => {
    if (window.$crisp != null) {
      return
    }

    if (!CRISP_WEBSITE_ID) {
      return
    }

    const s = document.createElement('script')

    s.type = 'text/javascript'
    s.async = true
    s.src = 'https://client.crisp.chat/l.js'
    window['CRISP_WEBSITE_ID'] = CRISP_WEBSITE_ID
    document.getElementsByTagName('head')[0].appendChild(s)
  }, [])
}
