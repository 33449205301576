import { useEffect } from 'react'

const CLARITY_PROJECT_ID: string | undefined = import.meta.env.VITE_CLARITY_PROJECT_ID

export const useClarity = () => {
  useEffect(() => {
    if (window.clarity != null) {
      return
    }

    if (!CLARITY_PROJECT_ID) {
      return
    }

    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.append(`
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            ;(c[a].q = c[a].q || []).push(arguments)
          }
        t = l.createElement(r)
        t.async = 1
        t.src = 'https://www.clarity.ms/tag/' + i
        y = l.getElementsByTagName(r)[0]
        y.parentNode.insertBefore(t, y)
      })(window, document, 'clarity', 'script', '${CLARITY_PROJECT_ID}')
    `)
    document.getElementsByTagName('head')[0].appendChild(s)
  }, [])
}
