import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import DebounceLink from 'apollo-link-debounce'

import { AuthCookieService } from '../../utils/AuthCookieService'

export type Client = ApolloClient<NormalizedCacheObject>

const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const cache = new InMemoryCache()

  const httpLink = createHttpLink({
    uri: import.meta.env.VITE_API_GRAPHQL_ENDPOINT,
  })

  const authLink = setContext(async (_, { headers }) => {
    const token = AuthCookieService.getAuthInfo()?.accessToken

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  return new ApolloClient({
    cache,
    link: ApolloLink.from([new DebounceLink(300), authLink.concat(httpLink)]),
  })
}

export const apolloClient = getApolloClient()
