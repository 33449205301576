import styled from 'styled-components'

export const Container = styled.div`
  min-width: 100vw;
  min-height: 100svh;

  display: flex;

  /* Avoid Chrome to see Safari hack */
  @supports (-webkit-touch-callout: none) {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
`
