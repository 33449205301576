import { DefaultTheme, createGlobalStyle } from 'styled-components'

const StyledTheme: DefaultTheme = {
  fontFamily: "'SF Pro', sans-serif",

  borderRadius: '5px',

  colors: {
    primary: '#5A58F2',
    secondary: 'white',
  },

  responsive: {
    tablet: {
      valueString: '768px',
      valueNumber: 768,
    },
    mobile: {
      valueString: '480px',
      valueNumber: 480,
    },
  },
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fontFamily};

    user-select: none;
  }

  .crisp-client {
    a {
      &.cc-1m2mf {
        @media (max-width: ${({ theme }) => theme.responsive.mobile.valueString}) {
          margin-bottom: 55px !important;
        }
      }
    }
  }
`

export { StyledTheme, GlobalStyle }
