import CircularProgress from '@mui/material/CircularProgress'

import { Container } from './Loader.styled'

interface Props {
  size?: number
}

export const Loader = (props: Props): JSX.Element => {
  const { size = 40 } = props
  return (
    <Container>
      <CircularProgress color="inherit" size={size} />
    </Container>
  )
}
