import { Container, LabelNotFound } from './404.styled'

export const NotFound = (): JSX.Element => {
  return (
    <Container>
      <LabelNotFound>
        <strong>Oops!</strong>
        <br />
        <strong>404</strong>
        <br />
        Page not found
      </LabelNotFound>
    </Container>
  )
}
