import { useEffect } from 'react'

const JIMO_PROJECT_ID: string | undefined = import.meta.env.VITE_JIMO_PROJECT_ID

export const useJimo = () => {
  useEffect(() => {
    if (window.jimo != null) {
      return
    }

    if (!JIMO_PROJECT_ID) {
      return
    }

    window.jimo = []

    const s = document.createElement('script')

    s.type = 'text/javascript'
    s.async = true
    s.src = 'https://undercity.usejimo.com/jimo-invader.js'
    window['JIMO_PROJECT_ID'] = JIMO_PROJECT_ID
    document.getElementsByTagName('head')[0].appendChild(s)
  }, [])
}
