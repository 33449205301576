import { useEffect, useState } from 'react'
import { useLocation, Outlet } from 'react-router-dom'

import moment from 'moment'
import 'moment/dist/locale/fr'

import { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'

import { configurei18n, Language } from '@libs/i18n/i18n'

import { Loader } from '@components/Loader'

export const I18nContainer = (): JSX.Element | null => {
  const location = useLocation()

  // Language is valid. Check RootElement from /containers/root.container.tsx
  const getLanguageFromPath = (): Language => {
    const language = location.pathname.split('/')[1] as Language
    return language
  }

  const [language, setLanguage] = useState<Language | undefined>()

  const pathLanguage = getLanguageFromPath()

  useEffect(() => {
    if (pathLanguage) {
      // eslint-disable-next-line import/no-named-as-default-member
      moment.updateLocale('fr', {
        week: {
          dow: 1,
          doy: 1,
        },
      })

      registerLocale('fr', fr as any)

      configurei18n(pathLanguage)

      setLanguage(pathLanguage)
    }
  }, [pathLanguage])

  return language ? <Outlet /> : <Loader />
}
