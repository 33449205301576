import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Organization } from '@types'
import { LocalStorageService } from '@utils/LocalStorageService'

interface Size {
  width: number | undefined
  height: number | undefined
}

export interface AppState {
  organization?: Organization | null
  isSideBarMenuOpen: boolean
  isZoningHintOpen: boolean
  isZoomingPlanning: boolean
  windowSize: Size
}

const IS_SIDE_BAR_MENU_OPEN = 'IS_SIDE_BAR_MENU_OPEN'
const IS_ZONING_HINT_OPEN = 'IS_ZONING_HINT_OPEN'
// const IS_ZOOMING_PLANNING = 'IS_ZOOMING_PLANNING'

const isSideBarMenuOpen: boolean | null = LocalStorageService.get(IS_SIDE_BAR_MENU_OPEN)
const isZoningHintOpen: boolean | null = LocalStorageService.get(IS_ZONING_HINT_OPEN)

const getInitialState = (): AppState => ({
  organization: undefined,
  isSideBarMenuOpen: isSideBarMenuOpen != null ? isSideBarMenuOpen : true,
  isZoningHintOpen: isZoningHintOpen != null ? isZoningHintOpen : true,
  isZoomingPlanning: false,
  windowSize: { width: undefined, height: undefined },
})

export type SetOrganizationType = {
  organization: Organization | null
}

export type SetSideBarMenuOpenType = {
  isOpen: boolean
}

export type SetZoningHintType = {
  isOpen: boolean
}

export type SetZoomingPlanningType = {
  isZooming: boolean
}

export type SetWindowSizeType = {
  size: Size
}

export const appSlice = createSlice({
  name: 'app',
  initialState: getInitialState(),
  reducers: {
    setOrganization: (state, action: PayloadAction<SetOrganizationType>) => {
      state.organization = action.payload.organization
    },
    setSideBarMenuOpen: (state, action: PayloadAction<SetSideBarMenuOpenType>) => {
      LocalStorageService.set(IS_SIDE_BAR_MENU_OPEN, action.payload.isOpen)

      state.isSideBarMenuOpen = action.payload.isOpen
      if (action.payload.isOpen) {
        state.isZoomingPlanning = false
      }
    },
    setZoningHintOpen: (state, action: PayloadAction<SetZoningHintType>) => {
      LocalStorageService.set(IS_ZONING_HINT_OPEN, action.payload.isOpen)

      state.isZoningHintOpen = action.payload.isOpen
    },
    setZoomingPlanning: (state, action: PayloadAction<SetZoomingPlanningType>) => {
      state.isZoomingPlanning = action.payload.isZooming

      if (action.payload.isZooming) {
        state.isSideBarMenuOpen = false
      }
    },
    clearAppState: (state) => {
      const initialState = getInitialState()
      state.organization = initialState.organization
      state.isSideBarMenuOpen = initialState.isSideBarMenuOpen
      state.isZoningHintOpen = initialState.isZoningHintOpen
      state.isZoomingPlanning = initialState.isZoomingPlanning
    },
    setWindowSize: (state, action: PayloadAction<SetWindowSizeType>) => {
      state.windowSize = action.payload.size
    },
  },
})
