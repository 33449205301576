import createTheme from '@mui/material/styles/createTheme'
import red from '@mui/material/colors/red'

export const MuiTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#4D4BE7',
    },
    error: {
      main: red.A400,
    },
  },
})
