import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en.json'
import fr from './fr.json'

export type Language = 'en' | 'fr'

export const DefaultLanguage: Language = 'fr'

export const Languages: Language[] = ['en', 'fr']

export const isLanguageValid = (language: string | undefined): boolean => Languages.indexOf(language as any) !== -1

export const getDefaultLanguage = (): string => {
  const navigatorLanguage = (navigator.language || '').split('-')[0]

  // Browser language priority. If not available fallback to "DefaultLanguage"
  const defaultLanguage = isLanguageValid(navigatorLanguage) ? navigatorLanguage : DefaultLanguage
  return defaultLanguage
}

export const configurei18n = (lng: Language): void => {
  // eslint-disable-next-line import/no-named-as-default-member
  i18next.use(initReactI18next).init({
    lng,
    // debug: true,
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
  })
}
