import { Container } from './root.styled'

interface Props {
  children: React.ReactNode
}

export const RootLayout = (props: Props): JSX.Element => {
  const { children } = props

  return <Container>{children}</Container>
}
