import ReactGA from 'react-ga4'

export class GoogleAnalyticsService {
  static init() {
    const gaMeasurementId: string | undefined = import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID
    if (!gaMeasurementId) {
      return
    }

    try {
      ReactGA.initialize([
        {
          trackingId: gaMeasurementId,
        },
      ])
    } catch (error) {
      console.error(error)
    }
  }
}
