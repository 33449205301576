import Hotjar from '@hotjar/browser'
import { User } from '@types'

let init = false

export class HotjarService {
  static init() {
    const siteIdString: string | undefined = import.meta.env.VITE_HOT_JAR_SITE_ID
    if (!siteIdString) {
      return
    }

    const hotjarVersion = 6

    try {
      const hotjarId = parseInt(siteIdString, 10)

      Hotjar.init(hotjarId, hotjarVersion)

      init = true
    } catch (error) {
      console.error(error)
    }
  }

  static setUser(user: User) {
    if (init) {
      Hotjar?.identify(user.id, {
        email: user.email,
      })
    }
  }
}
