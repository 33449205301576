export class LocalStorageService {
  static set<T>(key: string, value: T) {
    try {
      const stringVaue = JSON.stringify({ value })
      localStorage.setItem(key, stringVaue)
    } catch (error) {
      console.error(error)
    }
  }

  static get<T>(key: string): T | null {
    try {
      const stringValue = localStorage.getItem(key)
      if (stringValue) {
        const { value } = JSON.parse(stringValue)
        return value
      }
    } catch (error) {
      console.error(error)
    }

    return null
  }
}
