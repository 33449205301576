import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthCookieService } from '@utils/AuthCookieService'

export interface AuthState {
  accessToken?: string
  refreshToken?: string

  status: {
    signIn?: 'loading' | 'success' | 'failed'
    signUp?: 'loading' | 'success' | 'failed'
  }
}

// Check for userAccessToken to log in as ...
const queryParams = new URLSearchParams(window.location.search)
const accessToken = queryParams.get('accessToken')
if (accessToken) {
  AuthCookieService.storeAuthInfo({ accessToken }, true)
}

const getInitialState = (): AuthState => ({
  accessToken: AuthCookieService.getAuthInfo()?.accessToken,
  refreshToken: undefined,

  status: {
    signIn: AuthCookieService.getAuthInfo()?.accessToken ? 'success' : undefined,
    signUp: undefined,
  },
})

export type SignInSuccessType = {
  accessToken: string
  refreshToken: string
}

export type SignUpSuccessType = {
  accessToken: string
  refreshToken: string
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    signUpLoading: (state) => {
      state.status.signUp = 'loading'
    },
    signUpError: (state) => {
      state.status.signUp = 'failed'
    },
    signUpSuccess: (state, action: PayloadAction<SignUpSuccessType>) => {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
      state.status.signUp = 'success'
      state.status.signIn = 'success'
    },

    signInLoading: (state) => {
      state.status.signIn = 'loading'
    },
    signInError: (state) => {
      state.status.signIn = 'failed'
    },
    signInSuccess: (state, action: PayloadAction<SignInSuccessType>) => {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
      state.status.signIn = 'success'
    },

    signOut: (state) => {
      const initialState = getInitialState()
      state.accessToken = initialState.accessToken
      state.refreshToken = initialState.refreshToken
      state.status = initialState.status
    },
  },
})
