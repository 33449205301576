import { useEffect } from 'react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'

import { Loader } from '../Loader'

import { NotFound } from '@modules/main/pages/404'

const CHUNCK_ERROR = 'page-has-been-force-refreshed'

function saveChunckErrorIndex(index?: number) {
  window.localStorage.setItem(CHUNCK_ERROR, index == undefined ? '0' : `${index}`)
}

function getChunckErrorRepeatIndex(): number {
  const stringIndex = window.localStorage.getItem(CHUNCK_ERROR)

  try {
    const index = parseInt(stringIndex || '0', 10)
    return index
  } catch (error) {}

  return 0
}

export const ErrorBoundary = (): JSX.Element => {
  const error = useRouteError()

  useEffect(() => {
    if (!isRouteErrorResponse(error)) {
      console.warn(error)

      const chunckErrorRepeatIndex = getChunckErrorRepeatIndex()
      saveChunckErrorIndex(chunckErrorRepeatIndex + 1)

      setTimeout(() => {
        if (chunckErrorRepeatIndex < 3) {
          window.location.reload()
        } else {
          saveChunckErrorIndex(0)

          window.location.replace(window.location.origin)
        }
      }, 3000)
    }
  }, [error])

  return <>{isRouteErrorResponse(error) ? <NotFound /> : <Loader />}</>
}
