const AUTH_COOKIE_NAME = 'authInfo'

export type AuthInfoType = {
  accessToken: string
  refreshToken?: string
}

export class AuthCookieService {
  /**
   * Store authentication information to cookie
   */
  public static storeAuthInfo(authInfo: AuthInfoType, persistent: boolean): void {
    const defaultDuration = 1000 * 60 * 60 * 24 * 62
    const duration = (persistent ? defaultDuration : 0) as number
    AuthCookieService.store(AUTH_COOKIE_NAME, authInfo, duration)
  }

  /**
   * Get authentication information from cookie
   */
  public static getAuthInfo(): AuthInfoType | undefined {
    return AuthCookieService.get(AUTH_COOKIE_NAME)
  }

  /**
   * Clear authentication information cookie
   */
  public static clearAuthInfo(): void {
    const d: Date = new Date()
    d.setTime(0)
    document.cookie = `${AUTH_COOKIE_NAME}=;expires=${d.toUTCString()};path=/`
  }

  /**
   * Generic value store to cookie
   */
  private static store(cookieName: string, value: any, duration: number) {
    const cookieValue = JSON.stringify(value)

    let expires = ''
    if (duration) {
      const d: Date = new Date()
      d.setTime(d.getTime() + duration)
      expires = `expires=${d.toUTCString()};`
    }

    document.cookie = `${cookieName}=${cookieValue};${expires}path=/`
  }

  /**
   * Generic value get from cookie
   */
  private static get(cookieName: string): any {
    const name = `${cookieName}=`
    const decodedCookies: string[] = decodeURIComponent(document.cookie).split(';')

    for (const decodedCookie of decodedCookies) {
      let c: string = decodedCookie
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }

      // TODO proper error management
      if (c.indexOf(name) === 0) {
        const valueString = c.substring(name.length, c.length)

        return JSON.parse(valueString)
      }
    }
    return null
  }
}
