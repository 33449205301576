import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LabelNotFound = styled.div`
  font-size: 40px;
`
